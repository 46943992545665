<template>
  <div class="about">
    <div class="section1">
      <div class="leftandri">
        <img src="../../assets/img/about/about_left_img.jpeg" alt="" />
        <div class="ri-text">
          <p>
            京卡物联（北京）数据技术有限公司（以下简称“京卡物联”）成立于2019年8月，总部位于北京，是由中国物流与采购联合会多位专家发起设立的物流科技企业。京卡物联以互通物流产业资源为核心价值，以供应链增值服务、供应链金融服务、物流行业数据分析、物流节点智能化运营、物流行业发展规划、物流经济运行分析等为主营业务，以释放物流产业数据价值、引领行业安全合规发展为使命，致力于建设全国互联的物流产业数字经济生态。
          </p>
        </div>
      </div>
    </div>
    <div class="section2">
      <div class="center-wrapper">
        <h1>我们在做的事</h1>
        <h2>致力于为物流产业用户提供高效易用的工具和服务来提高物流效率，降低物流成本</h2>
        <div class="advantage">
          <div class="item" v-for="(advantage, idx) in dataAarr1" :key="idx">
            <div class="img-wrapper">
              <img :src="advantage.icon" alt="" />
              <p>{{ advantage.name[0] }}</p>
              <p>{{ advantage.name[1] }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section3">
      <div class="center-wrapper">
        <h1>合作的伙伴</h1>
        <h2>感谢，每一份支持和肯定</h2>
        <div class="logo-wrapper">
          <img v-for="(img,idx) in logoimgs" :key="idx" :src="img" alt="">
        </div>
      </div>
    </div>
    <div class="section4">
      <div class="center-wrapper">
        <h1>联系我们</h1>
        <h2>多种联系方式，随时响应，让你我的沟通不会受限</h2>
        <div class="text-img">
          <div class="text-info">
            <div class="infos">合作邮箱：{{contact.email}}</div>
            <div class="infos">合作电话：{{contact.phone}}</div>
          </div>
          <div class="img">
            <img src="../../assets/img/jkgzh_img.jpeg" alt="">
            <p>微信二维码</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {phone,email} from "@/util/constant";
export default {
  name: "magdec",
  data() {
    return {
      contact: {phone,email},
      dataAarr1: [
        {
          icon: require("@/assets/img/about/section2_img1.png"),
          name: ['网络货运平台']
        },
        {
          icon: require("@/assets/img/about/section2_img2.png"),
          name: ['网络货运','监管服务平台']
        },
        {
          icon: require("@/assets/img/about/section2_img3.png"),
          name: ["厂矿无人值守", "管理系统"]
        }
      ],
      logoimgs: [
        require("@/assets/img/about/img_logo1.png"),
        require("@/assets/img/about/img_logo2.png"),
        require("@/assets/img/about/img_logo3.png"),
        require("@/assets/img/about/img_logo4.png"),
        require("@/assets/img/about/img_logo5.png"),
        require("@/assets/img/about/img_logo6.png"),
        require("@/assets/img/about/img_logo7.png"),
        require("@/assets/img/about/img_logo8.png"),
        require("@/assets/img/about/img_logo9.png"),
        require("@/assets/img/about/img_logo10.png"),
        require("@/assets/img/about/img_logo11.png"),
        require("@/assets/img/about/img_logo12.png")
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.about {
  .section1 {
    background-position: center;
    height: 640px;
    width: 100%;
    .leftandri {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      padding-top: 100px;
      justify-content: center;
      img {
        height: 440px;
      }
      .ri-text {
        width: 500px;
        height: 364px;
        background: #4DADEE;
        margin-left: -31px;
        margin-top: 36px;
        padding: 32px 37px;
        p {
          font-size: 16px;
          font-family: Heiti SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 33px;
        }
      }
    }
  }
  .section2 {
    background: url(~@/assets/img/about/section2_bg.png);
    background-position: center;
    height: 570px;
    width: 100%;
    margin: 0 auto;
    padding-top: 72px;
    text-align: center;
    h1 {
      height: 30px;
      font-size: 30px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #333333;
      line-height: 26px;
      margin-bottom: 26px;
    }
    h2 {
      height: 21px;
      font-size: 22px;
      font-family: Heiti SC;
      font-weight: bold;
      color: #666666;
      line-height: 26px;
      margin-bottom: 69px;
    }
    .advantage {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .item {
        .img-wrapper {
          width: 255px;
          height: 255px;
          background: #A6D3F1;
          border: 4px solid #DAEBF7;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 66px;
            height: 66px;
            margin-bottom: 20px;
          }
          p {
            width: 153px;
            height: 24px;
            font-size: 24px;
            font-family: Adobe Heiti Std;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 26px;
            margin-top: 5px;
          }
        }
      }
    }
  }

  .section3 {
    width: 100%;
    height: 744px;
    background: #4DADEE;
    text-align: center;
    padding-top: 72px;
    h1 {
      height: 30px;
      font-size: 30px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 26px;
      margin-bottom: 26px;
    }
    h2 {
      height: 22px;
      font-size: 22px;
      font-family: Heiti SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 26px;
    }
    .logo-wrapper {
      padding-top: 59px;
      img {
        margin-right: 107px;
        margin-bottom: 23px;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  .section4 {
    width: 100%;
    height: 505px;
    background: #FAFAFA;
    text-align: center;
    padding-top: 72px;
    h1 {
      height: 29px;
      font-size: 30px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #333333;
      line-height: 26px;
      margin-bottom: 26px;
    }
    h2 {
      height: 21px;
      font-size: 22px;
      font-family: Heiti SC;
      font-weight: bold;
      color: #666666;
      line-height: 26px;
    }
    .text-img {
      margin: 0 80px;
      display: flex;
      margin-top: 65px;
      text-align: left;
      .text-info {
        flex: 1;
        margin-top: 50px;
        .infos {
          height: 23px;
          font-size: 24px;
          font-family: Heiti SC;
          font-weight: bold;
          color: #666666;
          line-height: 26px;
          margin-bottom: 26px;
          padding-left: 50px;
          &:nth-child(1) {
            background:  url(~@/assets/img/about/icon_phone.png);
            background-repeat: no-repeat;
          }
          &:nth-child(2) {
            background:  url(~@/assets/img/about/icon_email.png);
            background-repeat: no-repeat;
          }
        }
      }
      .img {
        flex: 0 0 200px;
        text-align: center;
        img {
          width: 190px;
        }
        p {
          height: 22px;
          font-size: 24px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #333333;
        }
      }
    }
  }
  
}
</style>
